<template>
<v-container fluid pa-0 class="height: 100vh;">
  <portal to="toolbarTitle">Properties map</portal>
  
  <v-container fluid pa-0 class="height: 100vh;">
      <div :style="'width: 100%; height: 100vh;'" :id="getMapId()"></div>
      <v-btn title="Show service areas" style="top: 56px;" shaped fixed dark top right absolute @click="nav = !nav"><v-icon>mdi-map-marker-radius</v-icon></v-btn>
  </v-container>

<v-navigation-drawer
      fixed
      right
      v-model="nav"
      width="400"
      id="ancient-history"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Service areas
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon @click="nav = !nav" color="lighten-1">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>
    
      <!--property-count-by-service-area-table @selectFence="selectFence($event)" class="overflow: auto;"/-->
      
    </v-navigation-drawer>

  
</v-container>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import {PropertyResource} from "@/resources"
//import PropertyCountByServiceAreaTable from "@/components/properties/PropertyCountByServiceAreaTable.vue"

export default {
  name: "properties-map-view",
  components : {},
  data: function () {
    return {
        map : null, 
        marker : null,
        nav : false,
        apiKey : process.env.VUE_APP_GOOGLE_API_KEY,
        // TODO get from default service area.
        geo : {latitude : 49.1450161, longitude: -123.8566661},
        selectedFence : null,
        selectedFencePolygon : null,
        infoWindow : null,
    };
  },
  beforeDestroy() {
      if (this.map) {
        google.maps.event.clearInstanceListeners(this.map);
      }
  },
  methods : {
    getMapId : function() {
        return "map" + this._uid
    },
    // getFence(fenceId) {
    //   return api.getServiceArea().fences.find(f => f.id === fenceId)
    // },
    // selectFence(fenceId) {

    //   // if existing fence, deselect
    //   if (this.selectedFencePolygon) {
    //     this.selectedFencePolygon.setMap(null)
    //   }

    //   this.selectedFence = this.getFence(fenceId)

    //   var coords = this.selectedFence.coordinates.map(c => {
    //     var parts = c.split(",")
    //     return { lat: parseFloat(parts[1]), lng: parseFloat(parts[0]) }
    //   })

    //   var coordBounds = this.getCoordBounds(coords)
      
    //   this.selectedFencePolygon = new google.maps.Polygon({
    //     paths : coords,
    //     strokeColor: "green",
    //     strokeWeight: 1})
     
    //  this.selectedFencePolygon.setMap(this.map)

    //  this.map.fitBounds(coordBounds); 
    // },
    getCoordBounds(coords) {
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < coords.length; i++) {
        bounds.extend(coords[i]);
      }
      return bounds
    },
    drawProperty(map, property) {
      var marker = new google.maps.Marker({
        position: { lat: property.latitude, lng: property.longitude },
        map : map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: "blue",
          strokeColor : "blue",
          fillOpacity: 0.6,
          scale: 2,
        },
        title: property.address,
      });
      marker.addListener("click", () => {
        this.infoWindow.setContent('<div class="text-h6">' + property.address + '</div><a id="property-link" data-property-id="' + property.id + '">View property file</a></div>');
        this.infoWindow.open({anchor: marker})
      }); 
      
    },
  },
  mounted () {
        const loader = new Loader({
          apiKey: this.apiKey,
          version: "weekly",
        });
        loader.load().then(() => {

          /* global google */
          // create bounds
          var center = new google.maps.LatLng(this.geo.latitude,this.geo.longitude)
          var mapOptions = {
            center: center,
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControl: true,
            mapTypeControl: true,
            fullscreenControl : false,
            panControlOptions: {
                  position: google.maps.ControlPosition.RIGHT_CENTER
            },
            zoomControl: true,
            zoomControlOptions: {
              style: google.maps.ZoomControlStyle.LARGE,
              position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            }
          }
              
          // create map & marker
          this.map = new google.maps.Map(document.getElementById(this.getMapId()),mapOptions)
          
          new PropertyResource("locations").get().then(result => {
            result.data.forEach(p => {
              this.drawProperty(this.map, p)
            })
          })
          
          this.infoWindow = new google.maps.InfoWindow();
          
          var that = this
          var clickHandler = function() {
            var pid = this.getAttribute("data-property-id")
            that.$router.push({ path: `/properties/${pid}` } )
          }
          
          this.infoWindow.addListener("content_changed", function() {
              var h = document.getElementById("property-link");
              if (h) {
                h.onclick = clickHandler
              }
          })
          // the first time the infowindow is added, we can't get the
          //element right away until domready, this is only for the
          // first element clicked.
          google.maps.event.addListener(this.infoWindow, 'domready', function() {
              var h = document.getElementById("property-link");
              if (h) {
                h.onclick = clickHandler
              }
          });

        });
  },
  watch: {
      
  },
}

</script>

<style>

</style>